/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import slugify from '@sindresorhus/slugify'
import { radius } from 'theme'
import { formatToNumberWithCommas } from 'helpers'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import Input from 'components/Input'
import Footer from 'components/Footer'
import Link from 'components/Link'
import search from 'static/svg/search.svg'
import ChoroplethChart from 'components/ChoroplethChart'

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const Countries = ({ pageContext: { countries, map } }) => {
  const [countriesOnPage, setCountriesOnPage] = useState([...countries])
  const [dataOnPage, setDataOnPage] = useState([
    ...countries.map(({ value, alpha3 }) => {
      return { id: alpha3, value }
    }),
  ])

  const handleSearchChange = (e) => {
    if (e.target.value) {
      const filteredCountries = countries.filter(
        ({ name }) => name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      )
      setCountriesOnPage(filteredCountries)
      setDataOnPage(
        filteredCountries.map(({ value, alpha3 }) => {
          return { id: alpha3, value }
        })
      )
    } else {
      setCountriesOnPage([...countries])
      setDataOnPage([
        ...countries.map(({ value, alpha3 }) => {
          return { id: alpha3, value }
        }),
      ])
    }
  }

  const onChoose = ({ id }) => {
    const country = countries.find(({ alpha3 }) => alpha3 === id)
    window.location.href = `/top-marketing-agencies/${slugify(country.name)}`
  }

  return (
    <App>
      <SEO
        title="Top marketing agencies."
        description="Find the top marketing agencies anywhere in the world"
        path="/top-marketing-agencies/"
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1>Top Marketing Agencies</H1>
          <Text mt="m" color="secondaryText" opacity={0.8} fontSize="l">
            Connect with thousands top marketing agencies that we have researched, compiled and verified from around the
            world.
          </Text>
          <Box my="l">
            <Input
              onChange={handleSearchChange}
              width="300px"
              placeholder="Search by country"
              pl="40px"
              iconPosition={{ left: '10px' }}
              iconComp={<StyledImage src={search} />}
              hasBoxShadow
              borderRadius={radius.m}
            />
          </Box>
          <Box height={{ mobile: '250px', tablet: '420px' }} width="100%">
            <ChoroplethChart data={dataOnPage} map={map} onClick={onChoose} />
          </Box>
          <Grid
            mt="m"
            py="m"
            px="s"
            width={['auto', '900px']}
            gridTemplateColumns={['1fr 1fr', 'repeat(4, 1fr)']}
            gridGap="m"
          >
            {countriesOnPage.map(({ id, name, value }) => {
              return (
                <Text
                  key={id}
                  as={Link}
                  href={`/top-marketing-agencies/${slugify(name)}`}
                  textAlign="left"
                  fontSize="m"
                  fontWeight="medium"
                  antiAliased
                  color="primary"
                >
                  {`${name} (${formatToNumberWithCommas(value)})`}
                </Text>
              )
            })}
          </Grid>
          <Grid
            mt="xl"
            py="s"
            px="s"
            width={['auto', '900px']}
            gridTemplateColumns={['1fr 1fr', 'repeat(4, 1fr)']}
            gridGap="s"
          >
            {countriesOnPage.map(({ id, name, value }) => {
              return (
                <Text
                  key={id}
                  as={Link}
                  href={`/top-marketing-agencies/${slugify(name)}`}
                  textAlign="left"
                  fontSize="xs"
                  fontWeight="normal"
                  antiAliased
                  color="secondaryText"
                >
                  {`Top Marketing Agencies in ${name}`}
                </Text>
              )
            })}
          </Grid>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Countries.propTypes = {
  pageContext: PropTypes.shape({
    countries: PropTypes.array.isRequired,
    map: PropTypes.object.isRequired,
  }).isRequired,
}

export default Countries
